






































































































































import { RequestPaging } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import PricingStrategy from "./pricing_strategy/index.vue";
import List from "./components/list.vue";

import {
  apiCategoryDel,
  apiCategoryLists,
  apiCategoryStatus,
} from "@/api/goods";


@Component({
  components: {
    LsDialog,
    LsPagination,
    List,
    PricingStrategy,
  },
})
export default class Category extends Vue {
  $refs!: { plTreeTable: any; strategyRef: any };

  pager = new RequestPaging();

  drawer = false;

  marketCategory = {
    title: "",
    id: "",
  };

  extend = {}

  getList(): void {
    this.pager
      .request({
        callback: apiCategoryLists,
        params: { pager_type: 1 },
      })
      .then((res) => {
        this.extend = res?.extend;
        
      });
  }
  handleStatus(value: number, id: number) {
    apiCategoryStatus({
      id,
      is_show: value,
    }).then(() => {
      this.getList();
    });
  }

  handleDelete(id: number) {
    apiCategoryDel(id).then(() => {
      this.getList();
    });
  }

  ifOperation(item: any) {
    // return !item.packge_id
    return !!item.is_change;
  }

  //定价
  handleStrategy() {
    this.$refs.strategyRef.openDialog();
  }

  // 选品
  showGoodsSelect(obj: any) {
    this.marketCategory.title = obj.name + "------选品页";
    this.marketCategory.id = obj.id;
    this.drawer = true;
  }

  created() {
    this.getList();
  }
}
