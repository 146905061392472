




































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'

@Component({
    components: {
        LsDialog
    }
})
export default class price_card extends Vue {
    // props
    @Prop({ default: () => [] }) value: any // 价格列表

    price: any[] = [10, 20, 50, 100, 200, 500, 1000, 3000, 5000, 10000, 999999]
    /* --------data end-------- */
    // 添加价格区间
    addprice(type: string) {
        let obj: object = {
            start_price: 0, //起始价
            end_price: 10, // 结束价
            fixed_price: 10, // 固定金额
            picing_factor: 100, //价格系数
            type: '2' // 1固定金额 2 价格系数
        }
        this.value[type].section.push(obj)
    }
    //   删除定价
    del_price(index: number, type: string) {
        // 当数量大于2个时第一个不可以删除
        if (index == 0 && this.value[type].section.length > 1) {
            this.$message.error('第一条价格区间不可删除')
            return false
        }
        this.value[type].section.splice(index, 1)
    }
    //格式话系数
    input_blur(value: number, index: number, type: string, key: string) {
        if (value >= 999) {
            this.value[type].section[index][key] = 999
        }
    }
    //   默认价格系数
    def_input_blur(value: number, type: string) {
        if (value >= 999) {
            this.value[type].picing_factor = 999
        }
    }
    //   价格区间验证
    select_change(item: any, index: number, type: string, key: string) {
        // 当前区间起始价不高于终止价
        if (item.start_price >= item.end_price) {
            return this.$message.error('当前‘价格区间’的结束值必须大于起始值')
        }
        // 第二条数据开始 当前‘价格区间’的起始值必须是上一个价格区间的结束值
        if (index >= 1 && item.start_price != this.value[type].section[index - 1].end_price) {
            return this.$message.error('当前‘价格区间’的起始值必须是上一个价格区间的结束值')
        }
    }
}
