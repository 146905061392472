var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ls-category"},[_c('div',{staticClass:"ls-category__top ls-card"},[_c('el-alert',{attrs:{"title":"温馨提示：1.用户可以根据商品分类搜索商品，请正确创建分类；2.点击分类名称前符号，显示该商品分类的下级分类。","type":"info","show-icon":"","closable":false}})],1),_c('div',{staticClass:"ls-category__content m-t-16 ls-card"},[_c('div',{staticClass:"ls-content__btns"},[(_vm.extend.self_operation_permit === 1)?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.$router.push({
            path: '/control-center/goods/category_edit',
            query: { ccid: _vm.$route.query.ccid },
          })}}},[_vm._v("新增商品分类")]):_vm._e(),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleStrategy}},[_vm._v("定价策略")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pager.loading),expression:"pager.loading"}],staticClass:"ls-content__table m-t-16"},[_c('u-table',{ref:"plTreeTable",attrs:{"data":_vm.pager.lists,"fixed-columns-roll":"","height":800,"treeConfig":{
          children: 'sons',
          expandAll: false,
        },"use-virtual":"","row-id":"id","border":false,"size":"mini"}},[_c('u-table-column',{attrs:{"tree-node":true,"prop":"name","label":"分类名称"}}),_c('u-table-column',{attrs:{"label":"分类图片"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex"},[_c('el-image',{staticStyle:{"width":"58px","height":"58px"},attrs:{"fit":"cover","src":scope.row.image}})],1)]}}])}),_c('u-table-column',{attrs:{"label":"是否显示"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.handleStatus($event, scope.row.id)}},model:{value:(scope.row.is_show),callback:function ($$v) {_vm.$set(scope.row, "is_show", $$v)},expression:"scope.row.is_show"}})]}}])}),_c('u-table-column',{attrs:{"prop":"sort","label":"排序"}}),(_vm.extend.self_operation_permit === 1)?_c('u-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex"},[(_vm.ifOperation(scope.row))?_c('div',{staticClass:"flex"},[_c('div',[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                        path: '/control-center/goods/category_edit',
                        query: { id: scope.row.id, ccid: _vm.$route.query.ccid },
                      })}}},[_vm._v("编辑")]),_c('el-divider',{attrs:{"direction":"vertical"}})],1),_c('ls-dialog',{attrs:{"content":("确定删除：" + (scope.row.name) + "？请谨慎操作。")},on:{"confirm":function($event){return _vm.handleDelete(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("删除")])],1)],1):_vm._e(),(_vm.extend.is_choice_packge === 0)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.showGoodsSelect(scope.row)}}},[(_vm.ifOperation(scope.row))?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),_vm._v(" 选品 ")],1):_vm._e()],1)]}}],null,false,1852914928)}):_vm._e()],1)],1)]),_c('el-drawer',{attrs:{"destroy-on-close":"","append-to-body":true,"visible":_vm.drawer,"size":"100%"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('template',{slot:"title"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.marketCategory.title)+" ")])]),_c('List',{attrs:{"marketCategoryId":_vm.marketCategory.id}})],2),_c('PricingStrategy',{ref:"strategyRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }