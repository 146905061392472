





























































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";

import {
  apiGoodSelectionLists,
  apiCategoryLists,
  apimarketindependentPricing,
  apiaddCategoryGoods,
} from "@/api/goods";
@Component({
  components: {
    lsPagination,
    LsDialog,
  },
})
export default class goods_manage_list extends Vue {
  @Prop({
    default: 1,
  })
  @Prop({
    default: "",
  })
  marketCategoryId!: number;

  pager: RequestPaging = new RequestPaging();
  searchObj: any = {
    name: "", //商品名称
    supplier_id: "",
    status: "", //商品状态
    min_price: "", //最低价
    max_price: "", //最高价
    min_profit: "", //最低利润
    max_profit: "", //最高利润
    is_supplier_up: "", //是否为平台/自营1平台0自营
    category_id: "", //分类id,
    store_category_id: "",
  };

  categoryList: any[] = [];
  categoryListFlat: any[] = [];
  checkList = [];

  //多选列表
  multipleSelection: any[] = [];
  //全选所有
  allCheck: boolean = false;

  loading: Boolean = false;

  // 获取详情

  //获取选品列表
  getList(page?: number) {
    page && (this.pager.page = page);
    this.searchObj.store_category_id = this.marketCategoryId;
    this.pager.request({
      callback: apiGoodSelectionLists,
      params: {
        ...this.searchObj,
      },
    });
  }

  //获取分类列表
  async getCategoryList(): Promise<void> {
    const res = await apiCategoryLists({});
    this.categoryList = res?.lists;
    this.categoryListFlatFun(res?.lists);
  }

  //分类过滤
  categoryListFlatFun(tree: any) {
    tree = JSON.parse(JSON.stringify(tree));
    let queen: any = [];
    let out = [];
    queen = queen.concat(tree);
    while (queen.length) {
      let first = queen.shift();
      if (first.sons) {
        queen = queen.concat(first.sons);
        delete first.sons;
      }
      out[first.id] = first.name;
    }
    this.categoryListFlat = out;
  }

  //新增
  async goodsPackgeGoodsAddCategoryGoods(arr: Array<object>): Promise<void> {
    if (arr.length === 0) {
      this.$message.warning("请选中商品");
      return;
    }
    const data: any = {
      category_array: [],
    };

    arr.map((item: any) => {
      data.category_array.push({
        goods_id: item.id,
        category_id: this.marketCategoryId,
      });
    });
    const res = await apiaddCategoryGoods(data); //新增
    if (res) this.getList();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getList(1);
  }

  //分类过滤
  categoryListFlatFilters(str: string) {
    const arr = str.split(",");
    let res: any = "";
    arr.map((item: any) => {
      res += `<p>${this.categoryListFlat[item]}</p>`;
    });
    return res;
  }

  //左下角全选
  allCheckbox(value: boolean) {
    const tableRef = this.$refs.paneTable as any;
    value ? tableRef.toggleAllSelection() : tableRef.clearSelection();
  }

  //表格全选事件
  handleSelectionChange(value: any) {
    this.multipleSelection = value;
    let checkedCount = value.length;
    this.allCheck = checkedCount === this.pager.lists.length;
  }

  created() {
    this.getList();
    this.getCategoryList();
  }
}
