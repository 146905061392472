




























































import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";

@Component({
  components: {
    LsDialog,
  },
})
export default class price_pane_b extends Vue {
  // props
  @Prop({ default: () => [] }) value: any; // 价格列表
  @Prop({ default: false }) is_category!: Boolean; //区分分类
  //   data
  options: any[] = [
    {
      value: "选项1",
      label: "黄金糕",
    },
    {
      value: "选项2",
      label: "双皮奶",
    },
    {
      value: "选项3",
      label: "蚵仔煎",
    },
    {
      value: "选项4",
      label: "龙须面",
    },
    {
      value: "选项5",
      label: "北京烤鸭",
    },
  ];
  /* --------data end-------- */

  //methods
  // 添加价格列表
  add_goods_cate() {
    // 定价列表
    let obj: object = {
      goods_category: [], // 商品分类
      sales_price: {
        //售价
        sales_price_type: '1', //定价依据：1 指导价 2 协议价
        picing_factor: 100, // 默认的价格系数
        section: [
          //区间列表
          // {  完整解释
          //   start_price: 0, //起始价
          //   end_price: 10, // 结束价
          //   fixed_price: 10, // 固定金额
          //   picing_factor: 100, //价格系数
          //   type: 2, // 1固定金额 2 价格系数
          // },
        ],
      },
      cost_price: {
        //成本价
        cost_price_type: '1', //定价依据：1 协议价 2 营销价
        picing_factor: 100, //默认的价格系数
        section: [],
      },
    };
    this.value.push(obj);
  }
  // 删除价格列表
  del_goods_cate(sort_index: number) {
    this.value.splice(sort_index, 1);
  }
  //格式
  input_blur(value: number, index: number) {
    if (value >= 999) {
      this.value[index].picing_factor = 999;
    }
  }
}
