














































import {
  apigetPlatformSelfPricingStrategy,
  apiplatformSelfPricingStrategy,
} from "@/api/goods";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import allPrice from "./all_price.vue";

@Component({
  components: {
    LsDialog,
    allPrice,
  },
})
export default class AddUnit extends Vue {
  $refs!: { lsDialog: any; form: any };

  setting_type: number = 1;
  is_choice_packge: number = 1;
  setting_form: object = {
    setting_type: 1, //定价类型：1 全平台 2 分平台
    price_type: "3", //定价类型：1 价格定价 2 分类定价 3 综合定价
    is_category: false, //区分类目定价
    price_list: [
      // 定价列表
      {
        goods_category: [], // 商品分类
        picing_factor: 100, // 默认的价格系数
        sales_price: {
          //售价
          sales_price_type: "1", //定价依据：1 指导价 2 协议价
          picing_factor: 100, // 默认的价格系数
          section: [
            //区间列表
            // {  //完整解释
            //   start_price: 0, //起始价
            //   end_price: 10, // 结束价
            //   fixed_price: 10, // 固定金额
            //   picing_factor: 100, //价格系数
            //   type: 2, // 1固定金额 2 价格系数
            // },
          ],
        },
        cost_price: {
          //成本价
          cost_price_type: "1", //定价依据：1 协议价 2 营销价
          picing_factor: 100, //默认的价格系数
          section: [],
        },
      },
    ],
  }; // 定价表
  setting_form2: any[] = [
    {
      pt: "京东",
      pt_code: "jd",
      setting_type: 1, //定价类型：1 全平台 2 分平台
      price_type: "1", //定价类型：1 价格定价 2 分类定价 3 综合定价
      is_category: false, //区分类目定价
      price_list: [
        // 定价列表
        {
          goods_category: [], // 商品分类
          picing_factor: 100, // 默认的价格系数
          sales_price: {
            //售价
            sales_price_type: "1", //定价依据：1 指导价 2 协议价
            picing_factor: 100, // 默认的价格系数
            section: [
              //区间列表
              // {  完整解释
              //   start_price: 0, //起始价
              //   end_price: 10, // 结束价
              //   fixed_price: 10, // 固定金额
              //   picing_factor: 100, //价格系数
              //   type: 2, // 1固定金额 2 价格系数
              // },
            ],
          },
          cost_price: {
            //成本价
            cost_price_type: "1", //定价依据：1 协议价 2 营销价
            picing_factor: 100, //默认的价格系数
            section: [],
          },
        },
      ],
    },
    {
      pt: "阿里",
      pt_code: "al",
      setting_type: 1, //定价类型：1 全平台 2 分平台
      price_type: "1", //定价类型：1 价格定价 2 分类定价 3 综合定价
      is_category: false, //区分类目定价
      price_list: [
        // 定价列表
        {
          goods_category: [], // 商品分类
          picing_factor: 100, // 默认的价格系数
          sales_price: {
            //售价
            sales_price_type: "1", //定价依据：1 指导价 2 协议价
            picing_factor: 100, // 默认的价格系数
            section: [
              //区间列表
              // {  完整解释
              //   start_price: 0, //起始价
              //   end_price: 10, // 结束价
              //   fixed_price: 10, // 固定金额
              //   picing_factor: 100, //价格系数
              //   type: 2, // 1固定金额 2 价格系数
              // },
            ],
          },
          cost_price: {
            //成本价
            cost_price_type: "1", //定价依据：1 协议价 2 营销价
            picing_factor: 100, //默认的价格系数
            section: [],
          },
        },
      ],
    },
  ]; // 定价表
  tips: any[] = [
    {
      title: "销售定价说明：",
      text_tips: [
        "协议价50元，指导价100元，协议价 x 定价系数 即 50 × 130% = 65 元，指导价 × 定价系数 即 100 × 80% = 80 元， 默认为指导价 × 100% （指导价不变，协议价上涨可能导致亏损！）没有营销价的商品按照指导价进行计算",
      ],
    },
    {
      title: "成本定价说明：",
      text_tips: [
        "协议价50元，指导价100元，即实际成本为50元。此功能可将协议价设置为120%，协议价即为60元。如启动利润分佣， 不设置成本，则默认按照50元 利润进行分佣，设置后按照40元利润进行分佣。没有营销价的商品按照指导价进行计算",
      ],
    },
    {
      title: "销售定价说明：",
      text_tips: [
        "根据商品的指导价、协议价、服务费率计算出商品成本和利润，通过定价系数，设定商品利润比例，再加上成本即可设定商品最终售价。 定价系数可设置为0-999的数值。服务费率根据您的账号版本决定，如：集团版服务费率为 1%。",
        "即：（协议价 + 协议价 x 服务费率%）为成本；指导价-（协议价+协议价*服务费率%）为利润；定价系数默认为100%。",
      ],
      important_tips:
        "注意：使用此定价方案，设置并保存后，系统将自动开启风控策略并设置为：产品售价 < 协议价时，商品自动下架。",
    },
  ]; //提示
  identity: boolean = true; //编辑 false 新增true

  closeDialog() {
    this.$refs.lsDialog.close();
  }
  async openDialog() {
    await this.goodsPackgeDetail();
    this.$refs.lsDialog.open();
  }

  handleSave() {
    console.log(this.$refs.form);
    if (this.is_choice_packge == 1) {
      return;
    }
    apiplatformSelfPricingStrategy({
      platform_self_pricing_strategy: this.setting_form,
    })
      .then((res: any) => {
        this.goodsPackgeDetail();
        this.closeDialog();
        this.$message.success("更新成功");
      })
      .catch(() => {
        this.$message.error("更新失败");
      });
  }

  //
  // 获取商品包详情
  async goodsPackgeDetail(): Promise<void> {
    const res = await apigetPlatformSelfPricingStrategy({});
    if (res) {
      !Array.isArray(res.info) && (this.setting_form = res.info);
      this.is_choice_packge = res.is_choice_packge;
    }
  }
}
